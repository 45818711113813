.about {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
  }
  
  .aboutTop {
    width: 100%;
    height: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .aboutBottom {
    display: flex;
    flex-direction: column;
    height: 55%;
    align-items: center;
    margin-top: 50px;
  }
  
  .aboutBottom p {
    width: 1200px;
  }
  
  .about .aboutBottom h1 {
    font-weight: 400;
    font-size: 70px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
  }