.footer {
    width: 100%;
    height: 200px;
    background-color: #121619;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .socialMedia svg{
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  } 
  
  .footer p {
    color: white;
  }

  /* @media only screen and (max-width: 900px) { 
    .footer .socialMedia svg {
      width: 70px;
    }
    .footer .socialMedia {
      padding-left: 50px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .footer .socialMedia svg {
      display: none;
    }
  
    .footer .socialMedia svg {
      justify-content: flex-end;
      padding-right: 50px;
    }
  
    .footer .socialMedia svg {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .footer .socialMedia svg {
      display: none;
    }
  }*/